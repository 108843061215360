import ConfigProps from '../../../shared/device/apiConfig';
import { PlanCardBase } from '../../../shared/plan/types';
import PlanCardProps from '../../presentational/PlanCard/planCardProps';

export type BroadbandPlanCardProps = PlanCardBase & ConfigProps;

export enum AvailabilityStatus {
  'NOT_CALLED' = 'NOT_CALLED',
  'LOADING' = 'LOADING',
  'FAILURE' = 'FAILURE',
  'SUCCESS' = 'SUCCESS',
}

export enum ServiceType {
  'VDSL' = 'VDSL',
  'ADSL' = 'ADSL',
  'Fibre' = 'Fibre',
  'Hyperfibre' = 'Hyperfibre',
  'WBB' = 'WBB', // check this
  'RCG' = 'RCG',
  'Wireless' = 'Wireless',
}

export enum Segment {
  'Business' = 'business',
  'Consumer' = 'consumer',
}

export interface TServiceType {
  type: string;
  provider: string;
  maxGigaBytes?: number;
  serverId?: string;
  rat: string;
}

export interface TAvailibilityResponse {
  availabilityCode: string;
  serviceType: Array<TServiceType>;
  coreProducts: Array<string>;
}

export interface TBroadBandPlanCardState {
  plan: PlanCardProps;
  isHidden?: boolean;
  urlToggle: boolean;
}

export interface TRrp {
  dollars: string;
  cents: string;
}

export interface BroadbandDiscount {
  id: string;
  amount: string;
  amountExclGst: string;
  rrp: string;
  rrpExclGst: string;
  discountPrice: string;
  discountPriceExclGst: string;
  strikethroughDiscountPrice: string;
  strikethroughDiscountPriceExclGst: string;
}

// Common interface for availability fetch
export interface BaseBroadbandPlanData {
  segment: Segment;
}

export type TBroadbandProductData = BaseBroadbandPlanData & {
  id: string;
  title: string;
  dataEntitlement?: string;
  serviceType: ServiceType;
  provider: Array<string>;
  rrp: string;
  rrpExclGst: string;
  discountPrice: string;
  discountPriceExclGst: string;
  discountPeriod: string;
  discountMessage: string;
  upSpeedUnits?: string; // is this used?
  upSpeed?: string; // is this used?
  downSpeedUnits?: string; // is this used?
  downSpeed?: string; // is this used?
  strikethroughDiscountPrice: string;
  strikethroughDiscountPriceExclGst: string;
  strikethroughMessage: string;
  discounts: Array<BroadbandDiscount>;
  disclaimer?: string | null;
  features: Array<string>;
  coreProducts?: Array<string>;
};

export interface PlanService {
  provider?: Array<string>;
  serviceType: ServiceType;
  dataAllowance?: string;
  rat?: string;
}

export interface TPlanVO extends PlanService {
  planId: string;
}

export interface AvailabilityFetchConfig {
  urlListenerId: string;
  base: string;
  planData?: BaseBroadbandPlanData;
  broadBandAddress: string;
  broadBandOnboardingApiKey: string;
}
