// This is used in AddressSearch to respond to Broadband Plan Card CTA clicks
export const CTA_ID_PREFIX = 'BroadPlanCard-addToCart-button';

export const MAXIMUM_STANDARD_SPEED = 700;
export const STARTER_SPEED = 50;

export const BUSINESS_GROUP = {
  business: '2degreesSMB',
  consumer: '2degrees',
};
export const TECHNOLOGY = {
  fibre: 'fibre',
  hyperfibre: 'hyperfibre',
  copper: 'copper',
  wireless: 'wireless',
};

export const SPEED = {
  max: 'max',
  standard: 'standard',
  starter: 'starter',
  wbb4G: '4G',
  wbb5G: '5G',
  wbbRCG: 'RCG',
  unknown: 'Unknown',
};

export const BILL_FREQUENCY = {
  month: 'monthly',
  fortnight: 'fortnight',
  week: 'weekly',
};

export const CAPACITY = {
  bandwidth300: '300',
  bandwidth600: '600',
  bandwidthUnlimited: 'unlimited',
};

export const BROADBAND_DISCOUNT = {
  payMonthlyBundle: 'payMonthlyBundle',
  powerBundle: 'powerBundle',
};

// URL price GST param value for GST exclusive flag
export const GST_EXCL = 'exc';
